import React from "react";
import "./AboutProject.css";
import { useNavigate } from "react-router-dom";

function AboutProject() {
  const navigate = useNavigate();

  const toDiptych = () => {
    navigate("/diptych");
  };
  return (
    <div className="aboutProject">
      <div className="aboutProject_diptych aboutProject_clickable" onClick={toDiptych}>
        <div className="aboutProject_title">Diptych</div>
        <div className="aboutProject_description">
          Diptych photography is a technique to add a story by putting two pictures side by side in a single frame.
          <br />
          This app allows users to make a diptych image by simply uploading two files.
        </div>
      </div>
    </div>
  );
}

export default AboutProject;
