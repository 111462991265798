import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import About from "./pages/About";
import Diptych from "./pages/projects/diptych";
import { Helmet, HelmetProvider } from "react-helmet-async";

function App() {
  const helmetContext = {};

  return (
    <HelmetProvider context={helmetContext}>
      <Helmet>
        <title>Yuichi Hamamoto</title>
      </Helmet>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<About />} />
            {/* <Route path="/blog/*" element={<Main />} /> */}
            <Route path="diptych" element={<Diptych />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </BrowserRouter>
      </div>
    </HelmetProvider>
  );
}

export default App;
