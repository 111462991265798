import React from "react";
import "./AboutMenu.css";

function AboutMenu(props: { page: number; setPage: (n: number) => void }) {
  const select = (num: number) => {
    props.setPage(0);
    setTimeout(() => {
      props.setPage(num);
    }, 600);
  };
  return (
    <div className="aboutMenu">
      <div className="aboutMenu__element">
        <div className={`aboutMenu__element__pointer ${props.page === 1 && "selected"}`} />
        <div
          className="aboutMenu__element__title"
          onClick={() => {
            select(1);
          }}
        >
          <b>Home</b>
        </div>
      </div>
      <div className="aboutMenu__element">
        <div className={`aboutMenu__element__pointer ${props.page === 2 && "selected"}`} />
        <div
          className="aboutMenu__element__title"
          onClick={() => {
            select(2);
          }}
        >
          <b>Info</b>
        </div>
      </div>
      <div className="aboutMenu__element">
        <div className={`aboutMenu__element__pointer ${props.page === 3 && "selected"}`} />
        <div
          className="aboutMenu__element__title"
          onClick={() => {
            select(3);
          }}
        >
          <b>Project</b>
        </div>
      </div>
    </div>
  );
}

export default AboutMenu;
