import React, { useState } from "react";
import "./About.css";
import AboutMenu from "./Menu";
import AboutHome from "./Home";
import AboutProject from "./Project";
import AboutInfo from "./Info";
import FadeInOut from "../../components/general/FadeInOut";

function About() {
  const [page, setPage] = useState(1);
  const style = { width: "100%", height: "100%" };

  const openNovelWorksWebsite = () => {
    window.open("https://www.novelworks.jp/", "_blank", "noopener,noreferrer");
  };

  return (
    <div className="about">
      <div className="about__top">
        <div className="about__top__image">
          <div className="ant" />
        </div>
      </div>
      <div className="about__container">
        <div className="about__left">
          <div className="about__left__header">
            <div className="about__left__header__name">Yuichi Hamamoto</div>
            <div className="about__left__header__title">
              CTO at{" "}
              <span className="about_clickable" onClick={openNovelWorksWebsite}>
                NovelWorks
              </span>
            </div>
          </div>
          <div className="about__left__menu">
            <AboutMenu page={page} setPage={setPage} />
          </div>
        </div>
        <div className="about__right">
          <FadeInOut show={page === 1} duration={500} style={style}>
            <AboutHome />
          </FadeInOut>
          <FadeInOut show={page === 2} duration={500} style={style}>
            <AboutInfo />
          </FadeInOut>
          <FadeInOut show={page === 3} duration={500} style={style}>
            <AboutProject />
          </FadeInOut>
        </div>
      </div>
    </div>
  );
}

export default About;
