import React from "react";
import "./AboutInfo.css";
import { useNavigate } from "react-router-dom";

function AboutInfo() {
  const navigate = useNavigate();

  const openBlog = () => {
    navigate("./blog");
  };

  const openInstagram = () => {
    window.open("https://www.instagram.com/its_yuichi/", "_blank", "noopener,noreferrer");
  };

  const openGithub = () => {
    window.open("https://github.com/YuichiHamamoto", "_blank", "noopener,noreferrer");
  };

  const openLinkedIn = () => {
    window.open("https://www.linkedin.com/in/yuichi-hamamoto-1248b3170/", "_blank", "noopener,noreferrer");
  };

  return (
    <div className="aboutInfo">
      <div className="aboutInfo__left">
        <div className="aboutInfo__left__list">
          <div className="aboutInfo__list__element" onClick={openInstagram}>
            <b>Instagram↗︎</b>
          </div>
          {/* <div className="aboutInfo__list__element" onClick={openBlog}>
            <b>Blog</b>
          </div> */}
        </div>
      </div>
      <div className="aboutInfo__right">
        <div className="aboutInfo__right__list">
          <div className="aboutInfo__list__element" onClick={openGithub}>
            <b>Github↗︎</b>
          </div>
          <div className="aboutInfo__list__element" onClick={openLinkedIn}>
            <b>LinkedIn↗︎</b>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutInfo;
